import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const Quotation = () => {
  const {
    wordpressPage: { content },
  } = useStaticQuery(graphql`
    query QuotationQuery {
      wordpressPage(wordpress_id: { eq: 10 }) {
        content
      }
    }
  `)

  return (
    <StyledQuotation
      dangerouslySetInnerHTML={{ __html: content }}
    ></StyledQuotation>
  )
}

const StyledQuotation = styled.div`
  font: 400 3rem/1.3 "Roboto", sans-serif;
  color: ${({ theme }) => theme.primary};
  text-align: center;
  border-bottom: 15px solid;
  margin-top: 40px;
  padding-bottom: 20px;
  text-transform: uppercase;
`

export default Quotation
