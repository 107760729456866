import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroImage from "../components/heroimage"
import Quotation from "../components/quotation"
import Teasers from "../components/teasers"

const IndexPage = () => (
  <Layout>
    <SEO title="Strona główna" />
    <HeroImage />
    <Quotation />
    <Teasers />
  </Layout>
)

export default IndexPage
