import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Teasers = () => {
  const data = useStaticQuery(graphql`
    query TeasersQuery {
      certificates: allWordpressPost(
        filter: { categories: { elemMatch: { slug: { eq: "certyfikaty" } } } }
      ) {
        edges {
          node {
            title
            link
            wordpress_id
          }
        }
      }
      methods: allWordpressPost(
        filter: { categories: { elemMatch: { slug: { eq: "metody" } } } }
      ) {
        edges {
          node {
            title
            link
            wordpress_id
          }
        }
      }
    }
  `)

  const getValues = (array, key) =>
    array[key].edges.map(({ node }) => ({
      link: node.link,
      title: node.title,
      id: node.wordpress_id,
    }))

  const methods = getValues(data, "methods")
  const certificates = getValues(data, "certificates")

  return (
    <StyledTeasers>
      <Teaser>
        <CategoryLink to="/metody">METODY</CategoryLink>
        <List>
          {methods.map(({ id, link, title }) => (
            <ListItem key={id}>
              <ItemLink to={link}>{title}</ItemLink>
            </ListItem>
          ))}
        </List>
      </Teaser>
      <Teaser>
        <CategoryLink to="/certyfikaty">CERTYFIKATY</CategoryLink>
        <List>
          {certificates.map(({ id, link, title }) => (
            <ListItem key={id}>
              <ItemLink to={link}>{title}</ItemLink>
            </ListItem>
          ))}
        </List>
      </Teaser>
      <Teaser>
        <CategoryLink to="/kontakt">KONTAKT</CategoryLink>
        <List>
          <ListContact>
            <div>Magdalena Bodzioch</div>
            <div>mgr fizjoterapii</div>
            <div>
              <a href="tel:+48519734542">+48 519 734 542</a>
            </div>
            <div>
              <a href="mailto:fizjobobas.mb@gmail.com">
                fizjobobas.mb@gmail.com
              </a>
            </div>
            <div>
              Facebook:{" "}
              <a
                href="https://www.facebook.com/fizjobobas"
                rel="nofollow noopener noreferrer"
                target="_blank"
              >
                fb/fizjobobas
              </a>
            </div>
          </ListContact>
        </List>
      </Teaser>
    </StyledTeasers>
  )
}

const StyledTeasers = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin: 20px 0 40px;
  justify-content: space-between;
`

const Teaser = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 0 10px;

  @media (min-width: 576px) {
    width: calc(50% - 20px);
  }

  @media (min-width: 768px) {
    width: calc(33.333% - 20px);
  }
`

const ListItem = styled.li`
  &:not(:last-child) {
    a {
      margin-bottom: 5px;
    }
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const CategoryLink = styled(StyledLink)`
  font-size: 2.8rem;
  color: ${({ theme }) => theme.primary};
`

const ItemLink = styled(StyledLink)`
  color: ${({ theme }) => theme.gray};
`

const List = styled.ul`
  list-style: none;
  padding: 0 0 0 5px;
`

const ListContact = styled.div`
  a {
    color: ${({ theme }) => theme.secondary};
  }
`

export default Teasers
