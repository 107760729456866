import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

const HeroImage = () => {
  const {
    wordpressPage: { featured_media },
  } = useStaticQuery(graphql`
    query HeroImageQuery {
      wordpressPage(wordpress_id: { eq: 10 }) {
        featured_media {
          title
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 960) {
                src
                srcSet
                srcSetWebp
                srcWebp
                sizes
                presentationWidth
                presentationHeight
                originalName
                originalImg
                base64
                aspectRatio
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledHeroImage>
      <Img fluid={featured_media.localFile.childImageSharp.fluid} />
      <StyledTitle>{featured_media.title}</StyledTitle>
    </StyledHeroImage>
  )
}

const StyledHeroImage = styled.div`
  position: relative;
`

const StyledTitle = styled.h2`
  top: 20px;
  right: 20px;
  margin: 0;
  display: none;
  max-width: 430px;
  padding: 10px 20px;
  position: absolute;
  font: 300 3rem "Roboto", sans-serif;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  text-shadow: 1px 1px rgba(0, 0, 0, 0.15);
  color: ${({ theme }) => theme.secondary};

  @media (min-width: 576px) {
    display: inline-block;
  }
`

export default HeroImage
